<template>
  <date-picker
    :disabled-date="notAfterToday"
    v-model="range"
    placeholder="Select period"
    :format="show_time ? DATETIME_FORMAT : DATE_FORMAT"
    range
    :lang="lang"
    @open="onOpen"
    ref="date_picker"
    :type="show_time ? 'datetime' : 'date'"
    :clearable="clearable"
    :shortcuts="shortcuts"
  >
    <template v-slot:icon-clear>
      <span class="la-times la"></span>
    </template>
  </date-picker>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import {
  createDateInUTC,
  DATE_API_FORMAT,
  DATE_FORMAT,
  DATETIME_FORMAT,
  getAllCurrentMonth,
  getCurrentMonth,
  getStartOfWeek,
} from "@/core/services/services";
import { mapMutations, mapState } from "vuex";
import * as types from "@/core/services/store/modules/page/types";

import moment from "moment";

const today = createDateInUTC();
let convertDate = (update_range, show_time, new_value, old_value, ...time) => {
  let need_update_range = update_range;
  let new_date = new_value && moment(new_value).format(DATE_API_FORMAT);
  let old_date = old_value && moment(old_value).format(DATE_API_FORMAT);
  if (show_time) {
    if (new_date && new_date !== old_date) {
      new_date = new Date(new_date);
      new_date.setHours(...time);
      new_date = moment(new_date).toISOString();
      need_update_range = true;
    } else {
      new_date = new_value && moment(new_value).toISOString();
    }
  }
  return { date: new_date, need_update_range };
};
export default {
  name: "CalendarPeriod",
  components: { DatePicker },
  props: {
    setPeriod: Function,
    is_past: Boolean,
    hide_default_range: Boolean,
    getPeriod: Function,
    default_range: {
      type: Object,
      default: undefined,
    },
    show_time: Boolean,
    clearable: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    range: [],
    open: false,
    skip_update_range: false,
    lang: {
      formatLocale: {
        firstDayOfWeek: 1,
      },
    },
    shortcuts: [
      {
        text: "Today",
        onClick() {
          let dt = createDateInUTC();
          return [dt, dt];
        },
      },
      {
        text: "Yesterday",
        onClick() {
          let dt = createDateInUTC();
          dt.setDate(dt.getDate() - 1);
          return [dt, dt];
        },
      },
      {
        text: "Last 3 days",
        onClick() {
          let dt = createDateInUTC();
          dt.setDate(dt.getDate() - 2);
          return [dt, createDateInUTC()];
        },
      },
      {
        text: "Last 7 days",
        onClick() {
          let dt = createDateInUTC();
          dt.setDate(dt.getDate() - 6);
          return [dt, createDateInUTC()];
        },
      },
      {
        text: "Last 30 Days",
        onClick() {
          let dt = createDateInUTC();
          dt.setDate(dt.getDate() - 29);
          return [dt, createDateInUTC()];
        },
      },
      {
        text: "Current Month",
        onClick() {
          return getCurrentMonth();
        },
      },
      {
        text: "Current Week",
        onClick() {
          let dt = createDateInUTC();
          dt.setDate(dt.getDate() - dt.getDay() + 1);
          // change td => getStartOfWeek()
          const currentDate = new Date();
          const currentDayOfWeek = currentDate.getDay();
          return currentDayOfWeek === 1
            ? [dt, createDateInUTC()]
            : [getStartOfWeek(), createDateInUTC()];
        },
      },
      {
        text: "Last Week",
        onClick() {
          let dt_from = createDateInUTC();
          dt_from.setDate(dt_from.getDate() - dt_from.getDay() + 1 - 7);
          let dt_to = createDateInUTC();
          dt_to.setDate(dt_to.getDate() - dt_to.getDay());
          return [dt_from, dt_to];
        },
      },
    ],
  }),
  computed: {
    ...mapState({
      filter: (state) => state.page.filter,
      page: (state) => state.page,
    }),
    DATE_FORMAT: () => DATE_FORMAT,
    DATETIME_FORMAT: () => DATETIME_FORMAT,
    API_FORMAT: function () {
      return DATE_API_FORMAT;
    },
  },
  methods: {
    ...mapMutations({
      changeFilter: `page/${types.CHANGE_FILTER}`,
    }),
    notAfterToday(date) {
      return this.is_past ? false : date > today;
    },
    onOpen() {
      let dt = createDateInUTC();
      let date_to = this.range[1] ? this.range[1] : createDateInUTC();
      if (date_to.getMonth() === dt.getMonth()) {
        dt.setDate(1);
        if (dt.getMonth() === 0) {
          dt.setMonth(11);
          dt.setFullYear(dt.getFullYear() - 1);
        } else {
          dt.setMonth(dt.getMonth() - 1);
        }
        if (
          !this.show_time &&
          this.$refs.date_picker.$refs.popup.$children &&
          this.$refs.date_picker.$refs.popup.$children[0]
        ) {
          this.$refs.date_picker.$refs.popup.$children[0].calendars = [
            dt,
            createDateInUTC(),
          ];
        }
        if (
          this.show_time &&
          this.$refs.date_picker.$refs.popup.$children &&
          this.$refs.date_picker.$refs.popup.$children[0] &&
          this.$refs.date_picker.$refs.popup.$children[0].$children[0]
        ) {
          this.$refs.date_picker.$refs.popup.$children[0].$children[0].calendars =
            [dt, createDateInUTC()];
        }
      }
    },
  },
  watch: {
    range(newValue, oldValue) {
      if (!this.skip_update_range) {
        let { date: date_from, need_update_range: update_from } = convertDate(
          this.skip_update_range,
          this.show_time,
          newValue[0],
          oldValue[0],
          0,
          0,
          0
        );
        this.skip_update_range = update_from;
        let { date: date_to, need_update_range: update_to } = convertDate(
          this.skip_update_range,
          this.show_time,
          newValue[1],
          oldValue[1],
          23,
          59,
          0
        );
        this.skip_update_range = update_to;

        let period = {
          date_from: date_from,
          date_to: date_to,
        };
        if (this.setPeriod) {
          this.setPeriod(period);
        } else {
          if (
            this.filter.date_from !== date_from ||
            this.filter.date_to !== date_to
          ) {
            let period = {
              date_from: date_from ? encodeURIComponent(date_from) : date_from,
              date_to: date_to ? encodeURIComponent(date_to) : date_to,
            };
            if (this.getPeriod) {
              this.getPeriod(period);
            } else {
              this.changeFilter(period);
            }
          }
        }
        if (this.skip_update_range) {
          this.range = [
            date_from && new Date(date_from),
            date_to && new Date(date_to),
          ];
        }
      } else {
        this.skip_update_range = false;
      }
    },
  },
  created() {
    if (
      this.hide_default_range &&
      this.filter &&
      this.filter.date_from &&
      this.filter.date_to &&
      !this.default_range
    ) {
      let date_from = new Date(decodeURIComponent(this.filter.date_from));
      let date_to = new Date(decodeURIComponent(this.filter.date_to));
      this.range = [
        date_from.toString() === "Invalid Date" ? null : date_from,
        date_to.toString() === "Invalid Date" ? null : date_to,
      ];
    }
    if (!this.hide_default_range) {
      if (this.default_range) {
        this.range = [this.default_range.date_from, this.default_range.date_to];
      } else {
        if (this.is_past) {
          this.range = getAllCurrentMonth();
        } else {
          if (this.filter && this.filter.date_from && this.filter.date_to) {
            let date_from = new Date(decodeURIComponent(this.filter.date_from));
            let date_to = new Date(decodeURIComponent(this.filter.date_to));
            this.range = [
              date_from.toString() === "Invalid Date" ? null : date_from,
              date_to.toString() === "Invalid Date" ? null : date_to,
            ];
          } else {
            this.range = getCurrentMonth();
          }
        }
      }
    }
  },
};
</script>

<style scoped></style>
