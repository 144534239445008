<template>
  <div>
    <div class="blockUI blockOverlay" />
    <div
      class="blockUI blockMsg blockElement"
      :style="`${
        hideText
          ? 'width: 100%; height: 100%;display: flex; align-items: center;left: 0'
          : ''
      }`"
    >
      <div class="blockui" v-if="!hideText">
        <span>Please wait...</span
        ><span
          ><div class="kt-spinner kt-spinner--v2 kt-spinner--success"
        /></span>
      </div>
      <div
        v-else
        style="
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        "
      >
        <div class="kt-spinner kt-spinner--v2 kt-spinner--success" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loader",
  props: {
    hideText: {
      types: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped></style>
